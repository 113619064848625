.altar-area {
  width: 100%;
  height: 100%; }

.altar-molding {
  display: inline-block;
  height: 100vh;
  width: 12rem;
  border-right: #2222f7 solid 0.098rem; }

.altar-console {
  display: inline-block;
  height: 100%;
  width: fit-content; }
  .altar-console-cloth {
    width: 50rem;
    height: 35rem;
    border: #2222f7 solid 0.098rem;
    margin: 4rem; }

.door-frame {
  background-color: white;
  width: fit-content;
  height: fit-content;
  padding: 3.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 8rem;
  overflow: auto;
  border-radius: 1rem; }

.door-molding {
  margin-left: auto;
  margin-right: auto;
  width: fit-content; }
  .door-molding-crown {
    font-size: 1.4rem;
    color: #c2c2c2; }
  .door-molding-etching:focus {
    border: 0.03px solid #004cff; }
  .door-molding-etching {
    width: 24rem;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 0.03px solid #b4cbff;
    border-radius: 0.2rem;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none; }

.door-handle-enter {
  width: 24rem;
  height: 3rem;
  border-radius: 0.2rem;
  color: white;
  background: linear-gradient(103deg, #0632d0 0%, #224eec 74%, #0061d8 81%, #2750e3 97%);
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  overflow: auto; }
  .door-handle-enter-text {
    margin-top: 0.5rem; }

.door-handle-navigate-login {
  display: inline-block;
  width: fit-content; }

.door-handle-navigate-forgotpw {
  display: inline-block;
  float: right;
  margin-left: auto;
  margin-right: auto;
  width: fit-content; }

.porch {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: linear-gradient(103deg, #002bc9 0%, #224eec 74%, #0061d8 81%, #5479ff 97%); }

.path {
  color: white;
  font-size: 1.9rem;
  font-family: "Nunito", sans-serif;
  margin-right: 1.5rem;
  margin-left: 8rem;
  margin-top: 2rem;
  width: fit-content; }

.greeterblock {
  margin-bottom: 4rem;
  padding-bottom: 4rem; }

.greeterleft {
  display: inline-block;
  color: white;
  font-size: 1.4rem;
  font-family: "Nunito", sans-serif;
  margin-right: 1.5rem;
  margin-left: 8rem;
  width: fit-content; }

.greeterright {
  display: inline-block;
  color: #4a27f8;
  background-color: white;
  font-size: 1.9rem;
  font-family: "Nunito", sans-serif;
  margin-right: 4rem;
  float: right;
  text-align: center;
  width: fit-content;
  width: 2rem;
  border-radius: 0.4rem;
  font-weight: 900; }

.statue-trimmantel {
  height: 13.4rem;
  position: relative;
  background-color: #08087f; }

.statue-lantern {
  text-decoration: none;
  color: white;
  padding: 0.6rem;
  margin-top: 3rem;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 10rem;
  left: 7rem;
  text-align: left;
  font-size: 1.1rem; }

.statue-lattice {
  width: 100%;
  height: 100%;
  display: flex; }

.statue-zeusdb-trim {
  vertical-align: top;
  display: inline-block;
  width: 15rem;
  flex-grow: 0.1;
  background-color: white;
  border-right: 0.1rem #1a1ac7 solid;
  margin-left: 0; }

.statue-zeusdb-centerIsle {
  vertical-align: top;
  display: block;
  width: 100%; }

.statue-zeusdb-header {
  display: block;
  width: 100%;
  background: linear-gradient(103deg, #040689 0%, #09099e 14%, #030c8a 81%, #052597 92%);
  color: white;
  text-align: center;
  font-size: 5rem;
  padding-top: 3.5rem;
  padding-bottom: 4rem; }

.statue-zeusdb-plates {
  margin-top: 3rem;
  padding-bottom: 3rem;
  margin-left: 4rem;
  width: 37rem;
  margin-bottom: 3rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; }
  .statue-zeusdb-plates-engravings {
    width: 100%;
    height: 2.7rem;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-bottom: solid 0.09rem #130285;
    background-color: #130285; }
  .statue-zeusdb-plates-engraving {
    display: inline-block;
    width: fit-content;
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #5100ff;
    color: white;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    font-size: 0.9rem;
    margin-top: 0.35rem; }
  .statue-zeusdb-plates-zeusyoomlion {
    float: right;
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #5100ff;
    color: white;
    border-radius: 1rem;
    font-size: 0.9rem;
    margin-top: 0.35rem;
    margin-right: 0.75rem; }
  .statue-zeusdb-plates-code {
    background-color: #0a0a6f;
    color: white;
    padding-bottom: 4rem; }

.statue-zeusdb-platefunctions {
  font-size: 1.7rem;
  margin-left: 4rem;
  color: #858389; }

.statue-zeusdb-leaderplateplatforms {
  margin-left: 4rem;
  color: #858389; }

.statue-zeusdb-platetitles {
  margin-left: 4rem;
  font-size: 1.45rem;
  color: #858389; }

@media (max-width: 768px) {
  .statue-trimmantel {
    height: 13.4rem;
    position: relative;
    background-color: #08087f; }
  .statue-lantern {
    text-decoration: none;
    color: white;
    padding: 0.6rem;
    margin-top: 3rem;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    top: 9.4rem;
    left: 7.8rem;
    text-align: left;
    font-size: 1.1rem; }
  .statue-lattice {
    width: 100%;
    height: 100%;
    display: flex; }
  .statue-zeusdb-trim {
    vertical-align: top;
    display: inline-block;
    width: 15rem;
    flex-grow: 0.1;
    background-color: white;
    border-right: 0.1rem #1a1ac7 solid;
    margin-left: 0; }
  .statue-zeusdb-centerIsle {
    vertical-align: top;
    display: block;
    width: 100%; }
  .statue-zeusdb-header {
    display: block;
    width: 53rem;
    background: linear-gradient(103deg, #040689 0%, #09099e 14%, #030c8a 81%, #052597 92%);
    color: white;
    text-align: center;
    font-size: 5rem;
    padding-top: 3.5rem;
    padding-bottom: 4rem; }
  .statue-zeusdb-plates {
    margin-top: 3rem;
    padding-bottom: 3rem;
    margin-left: 4rem;
    width: 37rem;
    margin-bottom: 3rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; }
    .statue-zeusdb-plates-engravings {
      width: 100%;
      height: 2.7rem;
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      border-bottom: solid 0.09rem #130285;
      background-color: #130285; }
    .statue-zeusdb-plates-engraving {
      display: inline-block;
      width: fit-content;
      padding: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: #5100ff;
      color: white;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      border-radius: 1rem;
      font-size: 0.9rem;
      margin-top: 0.35rem; }
    .statue-zeusdb-plates-zeusyoomlion {
      float: right;
      padding: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: #5100ff;
      color: white;
      border-radius: 1rem;
      font-size: 0.9rem;
      margin-top: 0.35rem;
      margin-right: 0.75rem; }
    .statue-zeusdb-plates-code {
      background-color: #0a0a6f;
      color: white;
      padding-bottom: 4rem; }
  .statue-zeusdb-platefunctions {
    font-size: 1.7rem;
    margin-left: 4rem;
    color: #858389; }
  .statue-zeusdb-leaderplateplatforms {
    margin-left: 4rem;
    color: #858389; }
  .statue-zeusdb-platetitles {
    margin-left: 4rem;
    font-size: 1.45rem;
    color: #858389; } }

@media (max-width: 768px) {
  .hello {
    margin: 0rem;
    border-top: 3rem; }
  .comingsoon_background {
    position: fixed;
    width: 30rem;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center; }
  .comingsoon_cover {
    position: fixed;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    background-color: lightblue; }
  .zeus_header {
    margin-bottom: 5rem;
    background-color: #fcfffd;
    width: 100%;
    border-bottom: green solid 0.1rem; }
  .header_title {
    color: green; }
  .zeusdb_comingsoon {
    z-index: -1; }
  .cover {
    filter: blur(4.1px); }
  .container {
    position: relative;
    display: block;
    clip-path: polygon(0 0, 100% 0, 100% 83%, 0 100%);
    background: linear-gradient(5deg, #08aeea, #2af598);
    height: 100vh; }
    .container-labels {
      width: 61%;
      position: absolute;
      left: 0;
      top: 3.5rem; }
  .homepage_buttons {
    color: white;
    font-size: 1.4rem;
    font-family: "Nunito", sans-serif;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    width: fit-content; }
    .homepage_buttons-right {
      color: white;
      font-size: 1.4rem; }
  .homepage_info {
    position: relative;
    margin-left: 3.11rem;
    font-family: "Nunito", sans-serif;
    width: fit-content;
    margin-top: 11rem; }
    .homepage_info-titles {
      color: #f9fafd;
      font-size: 3.1rem; }
  .mobile_display {
    display: none; }
  .homepage_display {
    display: none;
    position: absolute;
    right: 0;
    top: 6.5rem;
    height: 45%;
    width: 45%; }
    .homepage_display-zeusdb {
      position: absolute;
      height: 30rem;
      width: 38rem;
      right: 0;
      font-size: 2rem;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      background-color: rgba(83, 95, 234, 0.733);
      color: white; }
      .homepage_display-zeusdb_graphblock {
        margin-top: 0.8rem;
        width: 7rem;
        height: 7rem;
        display: inline-block;
        position: relative;
        vertical-align: top;
        top: 0;
        left: 2rem;
        font-size: 0; }
        .homepage_display-zeusdb_graphblock-graph {
          display: inline-block;
          margin: 0;
          width: 1rem;
          height: 1rem;
          border: 0.01rem solid rgba(4, 39, 193, 0.633); }
      .homepage_display-zeusdb-titleblock {
        width: fit-content;
        display: block;
        margin-top: 1rem;
        margin-left: 2rem;
        letter-spacing: 0.3rem; }
      .homepage_display-zeusdb_navblock {
        display: inline-block;
        margin-left: 2rem;
        font-size: 1.4rem;
        letter-spacing: 0.23rem;
        vertical-align: top;
        margin-top: 0.2rem; }
        .homepage_display-zeusdb_navblock-title1 {
          border-bottom: gainsboro 0.1rem solid;
          margin-bottom: 0.3rem;
          padding-bottom: 0.5rem;
          width: fit-content; }
        .homepage_display-zeusdb_navblock-title12 {
          border-bottom: gainsboro 0.1rem solid;
          margin-bottom: 0.3rem;
          padding-bottom: 0.2rem;
          width: fit-content; }
      .homepage_display-zeusdb_displayblock {
        display: inline-block;
        margin-left: 3rem; }
        .homepage_display-zeusdb_displayblock-displays {
          background-color: rgba(255, 255, 255, 0.961);
          width: 12rem;
          height: 3rem;
          margin-bottom: 1rem;
          color: #000069;
          font-size: 1rem;
          padding: 1rem; }
          .homepage_display-zeusdb_displayblock-displays-title {
            color: #000069;
            font-size: 1rem; }
    .homepage_display-light {
      position: absolute;
      overflow: none;
      top: 13rem;
      height: 19rem;
      width: 23rem;
      right: 20rem;
      border-radius: 1.2rem;
      border: #dedede solid 0.63rem;
      color: white;
      background-color: white; }
      .homepage_display-light_objects-titlecontainer {
        width: 100%;
        border-bottom: #dedede solid 0.1rem; }
      .homepage_display-light_objects-panel {
        color: #000050;
        background-color: white;
        width: 14rem;
        height: 13rem;
        border-radius: 0.4rem;
        position: absolute;
        left: 3rem;
        height: fit-content; }
      .homepage_display-light_objects-title1 {
        margin: 1.5rem;
        margin-left: 1.7rem;
        color: white;
        background-color: white;
        width: fit-content;
        background-color: #00218d;
        width: 4rem;
        height: 2rem;
        text-align: center;
        vertical-align: middle; }
      .homepage_display-light_objects-title12 {
        margin: 1.5rem;
        margin-left: 1.7rem;
        color: #000077;
        background-color: white;
        width: fit-content;
        width: 4rem;
        height: 2rem;
        text-align: center;
        vertical-align: middle; }
      .homepage_display-light_objects-title2 {
        margin-top: 0.9rem;
        width: 5.2rem;
        text-align: center;
        border: #dedede solid 0.1rem; }
      .homepage_display-light_objects-title3 {
        border: #dedede solid 0.1rem;
        border-top: none;
        border-left: none;
        width: 4.3rem;
        height: 1.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .homepage_display-light_objects-subpanel1 {
        border-bottom: #c2c2c2 solid 0.1rem;
        width: 100%; }
  .homepage_nav {
    width: 100%;
    height: fit-content;
    text-align: center; }
    .homepage_nav-title {
      margin-right: 11.3rem;
      margin-top: 4.2rem;
      font-size: 2.76rem; }
    .homepage_nav_resources-right {
      margin-left: 2rem;
      display: inline-block; }
      .homepage_nav_resources-right-title1 {
        margin-right: 2rem; }
    .homepage_nav_resources-left {
      display: inline-block;
      margin-right: 2rem; }
      .homepage_nav_resources-left-title1 {
        margin-right: 1rem; }
      .homepage_nav_resources-left-title2 {
        background-color: rgba(255, 255, 255, 0.785);
        padding-right: 0.7rem;
        padding-left: 0.7rem;
        padding-bottom: 0.35rem;
        border-radius: 2.2rem; } }

@media (min-width: 769px) {
  .hello {
    margin: 0rem;
    border-top: 3rem; }
  .comingsoon_background {
    position: fixed;
    width: 30rem;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center; }
  .comingsoon_cover {
    position: fixed;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    background-color: lightblue; }
  .zeus_header {
    margin-bottom: 5rem;
    background-color: #fcfffd;
    width: 100%;
    border-bottom: green solid 0.1rem; }
  .header_title {
    color: green; }
  .zeusdb_comingsoon {
    z-index: -1; }
  .wave {
    width: 100%;
    bottom: 0;
    position: absolute;
    z-index: 99; }
  .mobile_display {
    display: inline-block; }
  .wave svg {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0; }
  .cover {
    filter: blur(4.1px); }
  .container {
    display: hidden;
    position: relative;
    display: block;
    clip-path: polygon(0 0, 100% 0, 100% 60%, 0 90%);
    background: linear-gradient(5deg, #08aeea, #2af598);
    height: 100vh; }
    .container-labels {
      width: 61%;
      position: absolute;
      left: 0;
      top: 3.5rem; }
  .homepage_buttons {
    color: white;
    font-size: 1.4rem;
    font-family: "Nunito", sans-serif;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    width: fit-content;
    text-decoration: none; }
    .homepage_buttons-right {
      color: white;
      font-size: 1.4rem; }
  .homepage_info {
    position: relative;
    margin-left: 11rem;
    font-family: "Nunito", sans-serif;
    width: fit-content;
    margin-top: 2.75rem; }
    .homepage_info-titles {
      color: #f9fafd;
      font-size: 4.6rem; }
  .homepage_display {
    display: hidden;
    position: absolute;
    right: 0;
    top: 6.5rem;
    height: 45%;
    width: 45%; }
    .homepage_display-zeusdb {
      position: absolute;
      height: 29rem;
      width: 38rem;
      right: 0;
      font-size: 2rem;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      background-color: rgba(83, 95, 234, 0.733);
      color: white; }
      .homepage_display-zeusdb_graphblock {
        margin-top: 0.8rem;
        width: 7rem;
        height: 7rem;
        display: inline-block;
        position: relative;
        vertical-align: top;
        top: 0;
        left: 2rem;
        font-size: 0; }
        .homepage_display-zeusdb_graphblock-graph {
          display: inline-block;
          margin: 0;
          width: 1rem;
          height: 1rem;
          border: 0.01rem solid rgba(4, 39, 193, 0.633); }
        .homepage_display-zeusdb_graphblock-title {
          width: fit-content;
          font-size: 1.5rem;
          margin-top: 0.3rem;
          margin-left: 0.1rem;
          letter-spacing: 0.15rem;
          color: white; }
      .homepage_display-zeusdb-titleblock {
        width: fit-content;
        display: block;
        margin-top: 1rem;
        margin-left: 2rem;
        letter-spacing: 0.3rem; }
      .homepage_display-zeusdb_navblock {
        display: inline-block;
        margin-left: 2rem;
        font-size: 1.4rem;
        letter-spacing: 0.23rem;
        vertical-align: top;
        margin-top: 0.2rem; }
        .homepage_display-zeusdb_navblock-title1 {
          border-bottom: gainsboro 0.1rem solid;
          margin-bottom: 0.3rem;
          padding-bottom: 0.5rem;
          width: fit-content; }
        .homepage_display-zeusdb_navblock-title12 {
          border-bottom: gainsboro 0.1rem solid;
          margin-bottom: 0.3rem;
          padding-bottom: 0.2rem;
          width: fit-content; }
      .homepage_display-zeusdb_displayblock {
        display: inline-block;
        margin-left: 3rem; }
        .homepage_display-zeusdb_displayblock-displays {
          background-color: rgba(255, 255, 255, 0.961);
          width: 12rem;
          height: fit-content;
          margin-bottom: 1rem;
          color: #000069;
          font-size: 1rem;
          padding: 1.07rem; }
          .homepage_display-zeusdb_displayblock-displays-title {
            color: #000069;
            font-size: 1rem; }
    .homepage_display-light {
      position: absolute;
      overflow: none;
      top: 12rem;
      height: 19rem;
      width: 23rem;
      right: 20rem;
      border-radius: 1.2rem;
      border: #dedede solid 0.63rem;
      color: white;
      background-color: white; }
      .homepage_display-light_objects-titlecontainer {
        width: 100%;
        border-bottom: #dedede solid 0.1rem; }
      .homepage_display-light_objects-panel {
        color: #000050;
        background-color: white;
        width: 17rem;
        height: 13rem;
        border-radius: 0.4rem;
        position: absolute;
        left: 3rem;
        height: fit-content; }
      .homepage_display-light_objects-title1 {
        margin: 1.5rem;
        margin-left: 1.7rem;
        color: white;
        background-color: white;
        width: fit-content;
        background-color: #00218d;
        width: 4rem;
        height: 2rem;
        line-height: 1.85rem;
        text-align: center;
        vertical-align: middle; }
      .homepage_display-light_objects-title12 {
        margin: 1.5rem;
        margin-left: 1.7rem;
        color: #000077;
        background-color: white;
        width: fit-content;
        height: 2rem;
        margin-left: 5.14rem;
        line-height: 2rem;
        text-align: center;
        vertical-align: middle; }
      .homepage_display-light_objects-title2 {
        margin-top: 0.9rem;
        width: 5.2rem;
        text-align: center;
        border: #dedede solid 0.1rem; }
      .homepage_display-light_objects-title3 {
        border: #dedede solid 0.1rem;
        border-top: none;
        border-left: none;
        width: 3.9rem;
        height: 1.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 1rem;
        padding-bottom: 1rem;
        line-height: 2rem;
        padding-left: 0.16rem; }
      .homepage_display-light_objects-subpanel1 {
        border-bottom: #c2c2c2 solid 0.1rem;
        width: 100%; }
  .homepage_nav {
    width: 100%;
    height: fit-content;
    text-align: center; }
    .homepage_nav-title {
      font-size: 2rem; }
    .homepage_nav_resources-right {
      margin-left: 2rem;
      display: inline-block; }
      .homepage_nav_resources-right-title1 {
        margin-right: 2rem; }
    .homepage_nav_resources-left {
      display: inline-block;
      margin-right: 8.85rem; }
      .homepage_nav_resources-left-title1 {
        margin-right: 1rem; }
      .homepage_nav_resources-left-title2 {
        background-color: rgba(255, 255, 255, 0.399);
        padding-right: 0.7rem;
        padding-left: 0.7rem;
        padding-bottom: 0.35rem;
        border-radius: 2.2rem; } }

.templegrid {
  position: relative;
  height: 100%;
  overflow: auto; }
  .templegrid-disclaimer {
    font-size: 0.6rem;
    width: fit-content;
    margin-bottom: 8rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.26rem; }
  .templegrid-courtyard-setting {
    position: fixed;
    height: 100vh;
    background: linear-gradient(170deg, #088cde 25%, #4808ea 62%, #8a28f9 91%);
    width: 100%;
    z-index: -200; }
  .templegrid-courtyard {
    color: white; }
    .templegrid-courtyard-plate {
      margin-top: 10rem;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      margin-bottom: 2rem; }
      .templegrid-courtyard-plate-investment {
        margin-bottom: 1rem; }
      .templegrid-courtyard-plate-proposals {
        margin-bottom: 1rem; }
      .templegrid-courtyard-plate-title {
        display: inline-block;
        font-size: 2rem;
        padding-bottom: 1.3rem; }
      .templegrid-courtyard-plate-price {
        display: inline-block;
        width: fit-content;
        margin-left: 5rem;
        font-size: 3rem;
        vertical-align: top;
        margin-right: 0.1rem;
        margin-top: 0.22rem; }
      .templegrid-courtyard-plate-ages {
        margin-bottom: 1rem;
        width: fit-content;
        text-align: left; }
      .templegrid-courtyard-plate-deposits {
        margin-bottom: 1rem;
        margin-top: 1rem; }
    .templegrid-courtyard-statue {
      text-align: center;
      border: white 0.05rem solid;
      padding: 2rem;
      width: fit-content;
      margin-left: auto;
      margin-right: auto; }

.container {
  position: relative;
  width: 100vw;
  height: 100vh; }

.inlines {
  display: inline-block; }

.inlines_cover {
  display: inline-block; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.block {
  display: block; }

.hind {
  margin-top: 1rem;
  text-align: center; }
